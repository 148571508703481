import {
  required,
} from "vuelidate/lib/validators";

import {
  onlyText,
  onlyAlphanumericGuion,
  onlyAlphanumericGuion2,
} from '@/_validations/validacionEspeciales'

import { DateFormater, NumberFormater } from "@/_helpers/funciones";

export default (dateRange) => {
  return {
    form: {
      Bl: {
        YardDocumentId: {},
        VesselId: { required },
        VisitIdOrItineraryId: { required },
        BlCargoId: { required },
        UbicationId: { required },
        TransactionDate: { 
          required,
          RangeDate(value) {
            return DateFormater.formatDateTimeWithoutSlash(value) >= dateRange.Bl.previousDate &&
              DateFormater.formatDateTimeWithoutSlash(value) <= dateRange.Bl.laterDate
          },
        },
        Dua: { onlyAlphanumericGuion2  },
        ReceiverName: { onlyText  },
        ReceiverCardId: { onlyAlphanumericGuion },
        ReceptionBpAct: { onlyAlphanumericGuion2  },
        Asycuda: { onlyAlphanumericGuion2  },
      },
      GeneralData: {
        PortActivityName: { required },
        Weight: {
          NotNegative(Value) { return NumberFormater.setNum(Value) < 0 ? false : true },
          required(Value) { return NumberFormater.setNum(Value) > 0 || NumberFormater.setNum(Value) < 0 ? true : false },
        },
        Volumen: {
          NotNegative(Value) { return NumberFormater.setNum(Value) < 0 ? false : true },
        },
        Quantity: {
          NotNegative(Value) { return NumberFormater.setNum(Value) < 0 ? false : true },
          required(Value) { return NumberFormater.setNum(Value) > 0 || NumberFormater.setNum(Value) < 0 ? true : false },
        },
      },
      DescriptionOfGoods:{
        DescriptionOfGoods: {},
      },
      Status: {
        YardDocumentStatusHistId: {},
        DocumentYardStatusId: { required },
        TransactionDate: { 
          required,
          RangeDate(value) {
            return DateFormater.formatDateTimeWithoutSlash(value) >= dateRange.Status.previousDate &&
              DateFormater.formatDateTimeWithoutSlash(value) <= dateRange.Status.laterDate
          },
        },
        Observation: {},
      }
    },
  }
}